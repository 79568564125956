<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>My {{ $layoutsLabelPlural }}</h1>
    </div>
    <div class="vap-page-content">
      <div v-if="loadingForms === true">
        <!-- If this is not here, the no-forms-div flashes for a second while the form list loads - not great -->
      </div>
      <div v-else-if="attributeFormsAvailable" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          @vasion-selection="handleRowSelect"
        />
      </div>
      <div v-else class="no-forms-div">
        <VasionEmptyForm />
        <div class="vasion-large-message">
          {{ greetingMessage }}
        </div>
        <div class="vasion-page-subheader instructional-message-div">
          It feels a little empty, create {{ $formsLabelWithPrefix }} to get started!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'AllMyForms',
  components: {
    Loading,
  },
  data: function () {
    return {
      columns: {
        Values: [],
        ValueType: 'String',
      },
      deleteFormID: 0,
      deleteFormName: '',
      greetingMessage: 'Hello!',
      headerCheckboxToggled: false,
      hiddenColumns: ['_FormID_', 'Workflow ID'],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      loadingForms: true,
      showDeleteDialog: false,
      tableData: {},
    }
  },
  computed: {
    attributeFormsAvailable() { return this.attributeFormsList && this.attributeFormsList.length > 0 },
    attributeFormsList() { return this.$store.state.attributeForm.myForms },
  },
  watch: {
    attributeFormsList: function () { this.buildTableData() },
  },
  async created() {
    this.columns.Values = [
      `${$layoutsLabel} Name`,
      '_FormID_',
      'Workflow ID',
    ]

    const payload = {
      StartNumber: 1,
      MaxReturnCount: 10000,
      IncludeUserSecurity: false,
      IncludeFields: false,
      PopulateOnlyNameAndID: true,
    }
    this.isLoading = true
    const promises = await Promise.all([
      this.$store.dispatch('attributeForm/getMyForms', payload),
      this.$store.dispatch('common/getEmptyAppGreeting'),
    ])
    // eslint-disable-next-line prefer-destructuring
    this.greetingMessage = promises[1]
    this.isLoading = false
    this.loadingForms = false
  },
  methods: {
    async buildTableData() {
      const data = {
        dataList: this.attributeFormsList,
        columns: this.columns,
      }

      this.tableData = await this.$store.dispatch('attributeForm/setFormTableDataNoEllips', data)
    },
    handleRowSelect(data) {
      this.$store.dispatch('forms/clearEform')
      this.$store.dispatch('mainViews/changeMainNav', data.Values[1])
      this.$router.push({ name: 'MyForm', params: { formName: data.Values[0], formID: data.Values[1]} })
    },
  },
}
</script>

<style lang="scss" scoped>
.no-forms-div {
  margin-top: 60px;
}

.grid-div {
  height: calc(100vh - 212px);
  overflow: auto;
  text-align: left;
}
</style>
